














import { Component, Vue } from "vue-property-decorator";
import VueMarkdown from "vue-markdown";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class Onboarding extends Vue {
  public source = `
# Onboarding

Navigation Sidebar
------------------

#### **_Home_**

This is where you can have an overview insight of the talent and the company. You are able to monitor and aware of the expiring contracts, top channels, announcements, activities, company views, and other insights.

#### **_Manage_**

Manage your individual talent managers, managers groups, and creators here! You'll be able to insert new or edit existing accounts based on the sections provided.

#### **_Mastersheet_**

Manage and view your talent's account, contract, youtube, payment, social media, and more here.

#### **_Export_**

To request and specify any information that you want to export.

Notifications
-------------

*   Notifications can be a major distraction. We keep things simple and only give notifications for important items of relevance.
*   You can view your notification in 2 different sections by clicking on different tabs: User (Your Notification) / System (By Bot)
*   Makes your view easier by filtering the unread/read notifications

Add New
-------

#### **_Manager Page_**

On this page, you are able to categorize or insert information based on sections you wanted. This category is divided into 2 main entities - **_managers and creators._**

#### **_Mastersheet Page_**

Add your new talent's account here! This will store all of their information to ease future company's reference and payments.

Customize your work
-------------------

#### **_Sort and Filter_**

Tasks aren't very useful until they're organized. When the volume gets out of hand, feel free to add a filter

*   Choose to show statuses in ascending or descending order with this option.
*   Search the keywords at the column search bar to find your result.

#### **_Column Customization_**

*   Remove unnecessary columns and clean up your view by a tick or untick the boxes needed.
*   Filters stay even after you made changes in managers/creators' profiles.
*   You can also save each change to the view as you go, ensuring you can always come back and it will be exactly how you left it!
*   You are always welcome to reset to defaults if there are any changes that want to be made from scratch.

Export
------

As the volume of our data is increasing, exporting can be one of the best ways for us to expand our business!

*   Either from the **_Managers_**/**_Mastersheet_** page, you are able to customize data that you wanted to export by filtering it out through sort or search!
*   All export files will be in CSV format.
*   Bonus: If you are having trouble exporting specific data, feel free to go to **_the export page_** and request the data that you want! You will receive it instantly!
`;
}
